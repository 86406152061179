@keyframes slideUpAndOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  
  .slide-up-and-out {
    animation: slideUpAndOut 0.5s ease-in-out;
    animation-fill-mode: forwards; /* Keeps the last animation state */
  }
  
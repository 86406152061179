
.ui.button {
  /* border: 2px dashed orange; */
}

.blog-links {
  display: flex;
  margin-bottom: 100px;
  justify-content: center;
  width: 100%;
  }

  @media (max-width: 768px) {
    .blog-links {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 50px;
      margin-left: 10px;
    }
  }


  .divider{
    width:5px;
    height:auto;
    display:inline-block;
  }

.post {
  position: relative;
  background-color: #fff;
  padding-top: 50px;
  /* border: 2px dotted forestgreen; */
}

.post__intro,
.post__body {

  padding: 1rem 3rem;
}

.post__intro {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post__intro__desc {
  margin-top: 10px;
  max-width: 800px;
  text-align: left;
  font-size: 18px;
}

.post__intro__img {
  width: 100%;
  max-width: 950px;
  height: 100%;
  max-height:500px;
  object-fit: cover;
}

.post__body {
  max-width: 700px;
  margin: auto;
}

.post__body p {
  font-size: 1.1rem;
  line-height: 28px;
}

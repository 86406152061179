.footer{
  position: fixed;
  bottom: 0px;
}

.site-footer{
  padding-top: 125px;
  padding-bottom: 30px;
}


.site-footer {
  background-image: url('../../assets/images/footerbg.jpg');
  background-size: cover;
  background-position: center;
}

.site-footer .footer-logo {
  margin-bottom: 40px;
}

.site-footer ul {
  padding: 0px;
  list-style-type: none;
}

.social-links {
  padding: 0px;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.social-links li {
  margin: 5px;
}

.social-links li a {
  padding: 5px;
  font-size: 18px;
}

.social-links li a i {
  transition: 0.1s ease-in-out;
}

.social-links li a:hover,
.social-links li a:hover i {
  transform: scale(1.1);
  color: #fff;
}

.site-footer ul.contact-details li {
  line-height: 2;
  justify-content: center;
}

.site-footer ul.contact-details li a i {
  margin-right: 10px;
}

.site-footer h4.footer-title {
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #fff;
}

.site-footer .copyrights-row {
  border-top: 1px solid;
  color: #fff;
  padding: 35px 0 20px;
  margin-top: 6%;
}

.site-footer ul.footer-menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.site-footer ul.footer-menu li {
  margin: 10px 15px;
}

.site-footer ul.footer-menu li a {
  text-transform: uppercase;
  font-size: 18px;
}

.site-footer ul.footer-menu li a:hover {
  border-bottom: 1px solid currentColor;
}

.site-footer ul.footer-menu li a.active {
  font-weight: 700;
}


@media {
  .site-footer {
      text-align: center;
  }

  .site-footer ul {
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
  }
  .site-footer ul:not(.social-links) li {
      margin-left: auto;
      margin-right: auto;
  }

  .site-footer h4.footer-title {
      margin-bottom: 10px;
      margin-top: 50px;
  }
  .site-footer .social-links li {
      margin: 0 5px;
      justify-content: center;
  }
  .site-footer ul,
  .site-footer .social-links {
      margin-bottom: 40px;
  }

  .site-footer ul.footer-menu {
      justify-content: center;
  }

  .site-footer ul.footer-menu li {
      margin: 5px 15px;
  }
}

.footerHeader {
 background: linear-gradient(230deg, #d386da, #b83cc3, #7896fe ,#7abcfe, #abffff);
 background-size: 400% 400%;
 animation: gradient 20s ease infinite ;

 overflow:hidden;
 height: 8px;
 position: relative;
 }
 @keyframes gradient {
   0% {
     background-position: 0% 50%;
   }
   50% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0% 50%;
   }
 }




.section h1{
  font-size: 2rem;
}
@media (max-width: 774px){
  .section h1{
    font-size: 1.5rem;
    }
}
.text{
font-size: 1.5em;
word-spacing: normal;
letter-spacing: .3px;
margin-left: 20%;
margin-right: 20%;
 }
@media (max-width: 900px){
  .text{
 font-size: 1em;
  }
}
@media (max-width: 600px){
  .text{
    margin-left: 10%;
    margin-right: 10%;
  }
}
  @media (max-width: 400px){
    .text{
   font-size: .75em;
    }
 }

/* Status Quo Section */

.hero-section {
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: right top;
  padding: 8% 0 5%;
  overflow: hidden;
  position: relative;
  z-index: 9;
}

.hero-section h1 {
    font-size: 40px;
    width: 480px;
  }
  @media screen and (max-width: 1025px) {
  .hero-section h1{
    font-size: max(40px);
    font-size: 4.5vw;
    max-width: 480px;
    width: 50vw;
    margin-top: 0px;
    margin-bottom: 0px;
    transform: translateX(-75px);
  }
}

.hero-section .btn-prime {
  display: none;
  }
  @media screen and (min-width: 1025px) {
  .hero-section .btn-prime{
  margin-left: 168px;
  float: left;
  display: inline-block;
  }
  @media screen and (max-width: 1025px) {
  .hero-section .btn-prime{
    margin-left: 0px;
    float: left;
    display: inline-block;
    transform: translateX(-30%);
  }
  @media screen and (max-width: 700px) {
  .hero-section .btn-prime{
    margin-left: 0px;
    float: left;
    display: none;
    transform: translateX(-30%);
  }
}
}
}
.homeBackground {
  background: linear-gradient(-145deg, #f4a09c, #b83cc3, #7896fe ,#78f9ff, #40ff84);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite ;

  border-bottom-left-radius: 90%;
  border-top-left-radius: 0% ;
  overflow:hidden;
  position: absolute;
  right: 0px;
  transform: translateY(-10px);
  }
  @media (min-width: 775px){
    .homeBackground{
    height: 800px;
    width: 65vw;
  }
}
  @media (max-width: 774px){
  .homeBackground{
    height: 500px;
    width: 52.5vw;
  }
}
  @keyframes gradient {
    0% {
  		background-position: 0% 50%;
  	}
  	50% {
  		background-position: 100% 50%;
  	}
  	100% {
  		background-position: 0% 50%;
  	}
  }
/* Hero Section Collage */

.hero-section .img-wrap {
  max-width: 700px;
  max-height: 700px;
  width: 100%;
  height: 100%;
 }
 @media(max-width: 1025px) {
 .hero-section .img-wrap {
   max-height: 550px;
   max-width: 550px;
   height:85%;
   width:85%;
 }
}
 .hero-section .collage {
   padding-top: 0px;
   padding-right: 25px;
 }
 @media(max-width: 992px) {
 .hero-section .collage {
   padding-top: 200px;
   padding-right: 5px;
 }
}
.btn-prime {
  background: radial-gradient(ellipse at top, #23d8f8, transparent),
            radial-gradient(ellipse at bottom, #8a58ee, #9ed2ff);
            animation: gradientAnimation 5s infinite;
  background-size: 100% 100%;
  overflow:hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Creates the 3D effect */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25); /* Optional for text */
  transition: all 0.3s ease;

  /* Add a hover effect to enhance interactivity */
  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px); /* Slightly raise the button */
  }
}
/* How It Works Section */


.HowItWorksText {
  display: block;
  padding-left: 250px;
}
  @media (max-width: 577px){
  .HowItWorksText{
    display: block;
    padding-left: 0px;
  }
}

.img-texting{
  display: none;
  }
 @media (min-width: 576px){
   .img-texting{
     display: flex;
   }
}

.JoinButton{
  padding: 20px;
  padding-top: 50px;
}

.img-texting-mobile{
    display: none;
    width: 100%;
    padding-top: 20px;
  }
  @media (max-width:576px){
    .img-texting-mobile{
        display:block;
    }
}

/* start Campaigns Section */
.campaigns-section {
  background: var(--clr-prime);
  color: #fff;
  padding: 100px 0;
  margin-top: 8%;
  float: center;
  display: block;
}

.col-md-6.text-center.text-md-left {
  align-items: center !important;
}

.col-md-6.text-center.text-md-left{
  margin-left: 15%;
  margin-right: 15%;
}
  @media (max-width:576px){
    .col-md-6.text-center.text-md-left{
      margin-left: 5%;
      margin-right: 5%;
    }
}
.campaigns-section .img-wrap {
}

ul.list-ticked {
  padding: 0px;
  list-style-type: none;
}

ul.list-ticked {
  padding: 0px;
  list-style-type: none;
  font-size: 21px !important;
}

#checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    background: #be68cd;
    border-radius:50%;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

#checkmark:before{
    content:"";
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

#checkmark:after{
    content:"";
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}

ul.list-ticked li{
  margin: 10px 0;
}

/* Features Section */

.features-section .container {
  width: 100%;
}

.features-section .flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Background For Unmatched & Influencing Easy */

.bg-prime {
  background-color: var(--clr-prime);
  background-size: auto;
  min-height: 400px;
}

/* Influencing Easy Section */

.InfluencingEasyText {
  display: block;
   padding-left: 3%;
  padding-right: 3%;
}
  @media (min-width: 577px){
  .InfluencingEasyText{
    display: block;
    padding-left: 250px;
  }
}

.curved-img-section .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
}

.img-influencingEasy{
  transform: translateY(50px);
}

.img-influencingEasy{
  display: none;
  }
 @media (min-width: 576px){
   .img-influencingEasy{
     display: flex;
   }
}

.img-influencingEasyMobile{
    display: none;
  }
  @media (max-width:576px){
    .img-influencingEasyMobile{
        display:block;
    }
}

/*Capture an Audience Section */
.audience-section {
    padding-top: 200px;
  }
  @media (max-width: 576px){
  .audience-section{
    display: block;
    padding-top: 100px;
  }
}

/* Unmatched Engagement Section */

.unmatched-engagement{
  }
  @media (min-width: 999px){
  .unmatched-engagement{
    position: absolute;
    z-index: 9;
  }
}

.UnmatchedEngagementText {
  display: block;
  padding-right: 0px;
  }
  @media (min-width: 577px){
  .UnmatchedEngagementText{
    display: block;
    padding-right: 250px;
  }
}

.curved-img-section-right .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
}

.col-lg-7{
  align-items: center;
}

.unmatched-icon{
  transform: translateY(50px);
}

.unmatched-icon{
  display: none;
  }
 @media (min-width: 576px){
   .unmatched-icon{
      display: flex;
      justify-content: flex-end;
   }
}

.unmatched-mobile{
    display: none;
  }
  @media (max-width:576px){
    .unmatched-mobile{
        display:block;
    }
}




/* Eveything below is most likely old shit */

/*:root {
  --clr-prime: #151B55;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 40px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

a:hover {
  color: var(--clr-prime);
  text-decoration: none;
}

.text-light a:hover {
  color: #fff;
}

img {
  max-width: 100%;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  line-height: 1.4;
  margin-bottom: 25px;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.8;
} 
*/
/**************** THIS IS THE HEADER START ****************/

/* .container {
  max-width: 1380px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .container,
  .container-fluid {
      padding: 0 30px;
  }
}

.clr-prime {
  color: var(--clr-prime);
}

.bg-prime {
  background-color: var(--clr-prime);
}

.btn-prime {
  background: linear-gradient(90deg, #CCFFFF 0%, #B6DCFA 100%), #CCFFFF;
  border-radius: 50px;
  padding: 17px 40px;
  color: #333;
  font-size: 14px;
  display: inline-block;
}

.btn-prime:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
}

.site-header .site-logo img {
  width: 220px;
}

.site-header .menu-wrap .menu li {
  margin: 10px 15px;
}

.site-header .menu-wrap .menu li a {
  color: #fff;
}

.site-header .menu-wrap .menu li a:hover {
  color: var(--clr-prime);
}

.site-header .menu-wrap .menu li a.active {
  font-weight: 700;
  color: var(--clr-prime);
}

.home .site-header {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.site-header {
  background: #45B5E3;
}

.site-header .navigation-col {
  flex-grow: 2;
}

.site-header .header-btns li:first-of-type {
  margin-right: 25px;
}

.site-header .header-btns li a {
  color: #fff;
  width: max-content;
}

.site-header .header-btns li a i {
  margin-right: 10px;
}

.site-header .header-btns li a.login {
  padding: 12px 35px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  display: inline-block;
}

.site-header .header-btns li a:hover {
  opacity: 0.8;
}

.site-header .header-btns li a.login:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  opacity: 1;
} */


/* MOBILE NAV CSS STARTS HERE */

/* .site-header {
  z-index: 1111;
  padding: 10px 0;
}

.site-header .navbar-toggler {
  display: flex;
  align-items: center;
  color: #fff;
  outline: none;
  transition: 0.1s ease-in-out;
  transform-origin: center;
}

.site-header .navbar-toggler:active {
  transform: scale(0.9);
}

.site-header .navbar-toggler i {
  margin-right: 10px;
}

.site-header .navbar-collapse {
  background: #fff;
  margin-right: -15px;
  margin-left: -15px;
  padding: 0 30px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.site-header .navbar-collapse li:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
  margin: 5px 0;
}

.site-header .navbar-collapse .navbar-nav {
  padding: 20px 0;
}

.site-header .navbar-nav .nav-link {
  color: #151B55;
  font-weight: 500;
  text-transform: uppercase;
} */

/**************** THIS IS THE HEADER END ****************/


/* MAIN HEADER SECTION CSS STARTS HERE */


/* This section is for the purple circle in top right corner*/
/*.hero-section h1 {
  font-weight: 400;
  color: var(--clr-prime)
}*/


/* this section is for the surfer img in the hero-section*/
/*.hero-section .img-wrap{
  width: 180;
  height: 180;
  float: right;
  border-radius: 50%;
  border: 10px solid var(--clr-prime);
  overflow: hidden;
}*/


/* HOW IT WORKS SECTION \ SECTION 2 */
/*
.heading-style {
  font-weight: bold;
}

.heading-style::after {
  content: '';
  background: #CC99CC;
  width: 70px;
  border-radius: 50px;
  display: block;
  margin: 10px 0;
  height: 7px;
}

.text-center .heading-style::after,
.heading-style.text-center::after {
  margin: 10px auto
}

@media (min-width: 768px) {
  .text-md-left .heading-style::after {
      margin: 10px 0;
  }
}

.curved-img-section p {
  margin-bottom: 35px;
  line-height: 2;
}

.curved-img-section .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: max(calc(50vw - 1340px / 2), 15px);
}

.curved-img-section img {
  margin-top: max(-200px, -20%);
  margin-left: -15px;
  width: 100%;
}

.campaigns-section {
  background: var(--clr-prime);
  color: #fff;
  padding: 40px 0;
  margin-top: 6%;
}

.campaigns-section .img-wrap {
  transform: translateY(15%);
}

ul.list-ticked {
  padding: 0px;
  list-style-type: none;
}

ul.list-ticked li::before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 15px;
}

.features-section {
  padding: 7% 0 17%;
  background-image: url('../../assets/images/transparentbg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.features-section .feature-box .feature-box-icon {
  margin-bottom: 20px;
}

.features-section .feature-box .feature-box-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}

.features-section .feature-box .feature-box-inner {
  font-size: 15px;
}

.audience-section {
  padding: 6% 0 12%;
}

.curved-img-section-right p {
  margin-bottom: 0;
  line-height: 2;
}

.curved-img-section-right .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: max(calc(50vw - 1340px / 2), 15px);
}

.curved-img-section-right img {
  margin-top: max(-300px, -45%);
  transform: translateX(15px);
  width: 100%;
  margin-bottom: 60px;
  width: 100%;
}

.campaigns-section-right {
  background: var(--clr-prime);
  color: #fff;
  padding: 40px 0;
  margin-top: 6%;
}

.brands-section {
  padding: 6% 0 10%;
  background-image: url('../../assets/images/transparentbg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.brands-section .row>div {
  padding: 40px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-footer {
  padding-top: max(6%, 60px);
  background-image: url('../../assets/images/footerbg.jpg');
  background-size: cover;
  background-position: center;
} 

.site-footer .footer-logo {
  margin-bottom: 40px;
}

.site-footer ul {
  padding: 0px;
  list-style-type: none;
}

.social-links {
  padding: 0px;
  list-style-type: none;
  display: flex;
}

.social-links li {
  margin: 5px;
}

.social-links li a {
  padding: 5px;
  font-size: 18px;
}

.social-links li a i {
  transition: 0.1s ease-in-out;
}

.social-links li a:hover,
.social-links li a:hover i {
  transform: scale(1.1);
  color: #fff;
}

.site-footer ul.contact-details li {
  line-height: 2;
  max-width: 200px;
}

.site-footer ul.contact-details li a i {
  margin-right: 10px;
}

.site-footer h4.footer-title {
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.site-footer .copyrights-row {
  border-top: 1px solid #fff;
  padding: 35px 0 20px;
  margin-top: 6%;
}

.site-footer ul.footer-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  max-width: 400px;
  margin-left: auto;
}

.site-footer ul.footer-menu li {
  margin: 10px 15px;
}

.site-footer ul.footer-menu li a {
  text-transform: uppercase;
  font-size: 18px;
}

.site-footer ul.footer-menu li a:hover {
  border-bottom: 1px solid currentColor;
}

.site-footer ul.footer-menu li a.active {
  font-weight: 700;
}

@media (max-width: 1200px) {
  .site-header .header-btns li {
      margin: 15px 5px;
  }
  .site-header .header-btns li a {
      font-size: 0px;
  }
  .site-header .header-btns li a i {
      font-size: 16px;
      margin-right: 0px;
  }
  .site-header .header-btns li a,
  .site-header .header-btns li a.login {
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
  }
  .site-header .header-btns li a:hover {
      transform: translateY(-5px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
      opacity: 1;
  }
  .site-header .header-btns li:first-of-type {
      margin-right: 10px
  }
  .hero-section .img-wrap {
      max-width: 90%;
      margin: auto;
  }
}
*/
/* last commented out sec */
/*
 @media (max-width: 992px) {
  .site-header .menu-wrap .menu {
      display: none!important;
  }
  .hero-section .img-wrap {
      width: 400px;
  }
  .curved-img-section img,
  .curved-img-section-right img {
      margin: 0px;
      margin: 20px 0;
      transform: none;
  }
  .hero-section h1 {
      font-size: 36px;
  }
  .hero-section .btn-prime {
      margin: 10px!important;
  }
  h1 {
      font-size: 30px;
  }
  h2 {
      font-size: 30px;
  }
  .heading-style::after {
      height: 4px;
  }
  .curved-img-section p {
      line-height: inherit;
  }
}

@media (max-width: 768px) {
  .site-footer {
      text-align: center;
  }
  .site-footer ul {
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
  }
  .site-footer ul:not(.social-links) li {
      margin-left: auto;
      margin-right: auto;
  }
  .hero-section h1 {
      font-size: 30px;
  }
  .site-footer h4.footer-title {
      margin-bottom: 10px;
  }
  .site-footer .social-links li {
      margin: 0 5px;
  }
  .site-footer ul,
  .site-footer .social-links {
      margin-bottom: 40px;
  }
  .site-footer ul.footer-menu {
      justify-content: center;
  }
  .site-footer ul.footer-menu li {
      margin: 5px 15px;
  }
} 
*/

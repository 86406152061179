:root {
  --clr-prime: #151B55;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 40px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

a:hover {
  color: var(--clr-prime);
  text-decoration: none;
}

.text-light a:hover {
  color: #fff;
}

img {
  max-width: 100%;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  line-height: 1.4;
  margin-bottom: 25px;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.8;
}

/**************** THIS IS THE HEADER START ****************/

/*.container {
  max-width: 1380px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .container,
  .container-fluid {
      padding: 0 30px;
  }
} */

/* CLR PRIME, BG PRIME, BTN PRIME IS HOME PAGE ONLY*/

.clr-prime {
  color: var(--clr-prime);
}

.bg-prime {
  background-color: var(--clr-prime);
}

.btn-prime {
  background: linear-gradient(90deg, #CCFFFF 0%, #B6DCFA 100%), #CCFFFF;
  border-radius: 50px;
  padding: 17px 40px;
  color: #333;
  font-size: 14px;
}

.btn-prime:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
}


.dropdown-toggle.btn.btn-primary{
  background: radial-gradient(circle, #140c83, #140c83 80%, #7828c2 95%);
  border-radius: 20px;
  color: #333;
  color: #B993ee;
  display: block;
  text-align: center;
  border: none;
  cursor: pointer;
}

.dropdown-toggle.btn.btn-primary:hover {
  transform: translateY(-5px);
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.15);
}

.mobilebtns .mobile-link{
  color:#B993ee;
}

.mobilebtns{
 display: flex;
 width: auto;
 flex-wrap: wrap;
 justify-content: flex-end;
}


.mobilebtns button{
  border-radius: 20px;
  border: none;
  width: 60%;
  height: 50px;
  background: radial-gradient( #140c83, #140c83 80%, #7828c2 95%);
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.35);
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}

a.mobile-link{
}

.dropdown-menu{
  transform: translateY(65%);
}

.dropdown-menu.show{
  align-items:flex-end;
  transition: transform 0.8s, opacity 0.7s;
  transform:translate3d(-6%, 65%, 0px)!important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  right: 36px;
  width: 36px;
  height: 30px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #322d84;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #140c83;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0px;
  width: auto;
  max-width: 85%;
}

/* General sidebar styles */
.bm-menu {
  background: #110b69dc;
  padding: 1em 0.5em 0;
  font-size: 2em;
  text-align: left;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  padding: 1.5em;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.svg-inline--fa.fa-bars.fa-w-14.icon {
  display: block;
  float: left;
}

.site-header .site-logo img {
  position: absolute;
  top: 50px;
  left: 25px;
  margin-top: -25px;
}
@media (min-width: 775px) {
  .site-header .site-logo img {
    width: 240px;
  }
}
  @media (max-width: 774px) {
    .site-header .site-logo img {
      width: 180px;
      margin-left: -20px;
    }
}


.site-header .menu-wrap .menu li {
  margin: 10px 15px;
}

.site-header .menu-wrap .menu .link li {
  color: #fff;
}

.site-header .menu-wrap .menu li a:hover {
  color: var(--clr-prime);
}

.site-header .menu-wrap .menu li a.active {
  font-weight: 700;
  color: var(--clr-prime);
}

/* .home .site-header {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
} */


.site-header {
  background: #45B5E3;
}

/* .site-header .navigation-col {
  flex-grow: 2;
} */

/* .site-header .header-btns li:first-of-type {
  margin-right: 25px;
} */

/* .site-header .header-btns li a {
  color: #fff;
  width: max-content;
} */

/* .site-header .header-btns li a i {
  margin-right: 10px;
} */

/* .site-header .header-btns li a.login {
  padding: 12px 35px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  display: inline-block;
} */

/* .site-header .header-btns li a:hover {
  opacity: 0.8;
} */

/* .site-header .header-btns li a.login:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  opacity: 1;
} */


/* MOBILE NAV CSS STARTS HERE */
/*
.site-header {
  z-index: 1111;
  padding: 10px 0;
}
*/
/* .site-header .navbar-toggler {
  display: flex;
  align-items: center;
  color: #fff;
  outline: none;
  transition: 0.1s ease-in-out;
  transform-origin: center;
}

.site-header .navbar-toggler:active {
  transform: scale(0.9);
}

.site-header .navbar-toggler i {
  margin-right: 10px;
} */

/* .site-header .navbar-collapse {
  background: #fff;
  margin-right: -15px;
  margin-left: -15px;
  padding: 0 30px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.site-header .navbar-collapse li:not(:last-child) {
  border-bottom: 1px solid #e9e9e9;
  margin: 5px 0;
}

.site-header .navbar-collapse .navbar-nav {
  padding: 20px 0;
}

.site-header .navbar-nav .nav-link {
  color: #151B55;
  font-weight: 500;
  text-transform: uppercase;
} */

/**************** THIS IS THE HEADER END ****************/


/* MAIN HEADER SECTION CSS STARTS HERE */
/*
.hero-section h1 {
  font-weight: 400;
  position: right absolute;
  color: var(--clr-prime)
}
*/

/*
.hero-section {
  background: url('../../assets/images/homefeatured.png');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 12% 0 5%;
}
*/

.hero-section .img-wrap {
  border-radius: 50%;
  border: 15px solid var(--clr-prime);
  overflow: hidden;
}


/* HOW IT WORKS SECTION \ SECTION 2 */

/*
.heading-style {
  font-weight: bold;
}

.heading-style::after {
  content: '';
  background: #CC99CC;
  width: 70px;
  border-radius: 50px;
  display: block;
  margin: 10px 0;
  height: 7px;
}

.text-center .heading-style::after,
.heading-style.text-center::after {
  margin: 10px auto
}

@media (min-width: 768px) {
  .text-md-left .heading-style::after {
      margin: 10px 0;
  }
}

.curved-img-section p {
  margin-bottom: 35px;
  line-height: 2;
}

.curved-img-section .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: max(calc(50vw - 1340px / 2), 15px);
}

.curved-img-section img {
  margin-top: max(-200px, -20%);
  margin-left: -15px;
  width: 100%;
}
/*
.campaigns-section {
  background: var(--clr-prime);
  color: #fff;
  padding: 40px 0;
  margin-top: 6%;
}
*/

ul.list-ticked {
  padding: 0px;
  list-style-type: none;
}
/*
ul.list-ticked li::before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 15px;
}

/*converted this section to the homestyles.js file*/
/*.features-section {
  padding: 7% 0 17%;
  background-image: url('../../assets/images/transparentbg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.features-section .feature-box .feature-box-icon {
  margin-bottom: 20px;
}

.features-section .feature-box .feature-box-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}

.features-section .feature-box .feature-box-inner {
  font-size: 15px;
}*/

.audience-section {
  padding: 6% 0 12%;
}

/* Unmatched Engagement Section */
/*

.curved-img-section-right p {
  margin-bottom: 0;
  line-height: 2;
}

.curved-img-section-right .text-col {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: max(calc(50vw - 1340px / 2), 15px);
}

.curved-img-section-right img {
  margin-top: max(-300px, -45%);
  transform: translateX(15px);
  width: 35%;
  margin-bottom: 60px;
  float: right;
}
*/
.campaigns-section-right {
  background: var(--clr-prime);
  color: #fff;
  padding: 40px 0;
  margin-top: 6%;
}

.brands-section {
  padding: 6% 0 10%;
  background-image: url('../../assets/images/transparentbg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.brands-section .row>div {
  padding: 40px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .site-header .header-btns li {
      margin: 15px 5px;
  }
  .site-header .header-btns li a {
      font-size: 0px;
  }
  .site-header .header-btns li a i {
      font-size: 16px;
      margin-right: 0px;
  }
  .site-header .header-btns li a,
  .site-header .header-btns li a.login {
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50px;
  }
  .site-header .header-btns li a:hover {
      transform: translateY(-5px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
      opacity: 1;
  }
  .site-header .header-btns li:first-of-type {
      margin-right: 10px
  }
  .hero-section .img-wrap {
      max-width: 90%;
      margin: auto;
  }
}

@media (max-width: 990px) {
  .site-header .header-btns li {
    display: none;
  }
}


@media (max-width: 992px) {
  .site-header .menu-wrap .menu {
      display: none!important;
  }
  .hero-section .img-wrap {
      width: 400px;
  }
}
@media (max-width: 992px) {
  .site-header .menu-wrap .menu {
      display: none!important;
  }
  .hero-section .img-wrap {
      width: 400px;
  }
}
@media (min-width: 1650px) {
  .site-header .menu-wrap .menu {
      font-size: 2rem;
  }
}
@media (min-width: 1650px) {
  .site-header .menu-wrap .account-btns {
        font-size: 2rem;
  }
}

  /*
  .curved-img-section img,
  .curved-img-section-right img {
      margin: 0px;
      margin: 20px 0;
      transform: none;
  }
  .hero-section h1 {
      font-size: 36px;
  }
  .hero-section .btn-prime {
      margin: 10px!important;
  }
  h1 {
      font-size: 30px;
  }
  h2 {
      font-size: 30px;
  }
  .heading-style::after {
      height: 4px;
  }
  .curved-img-section p {
      line-height: inherit;
  }
}
*/

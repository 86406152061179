

section {
    height: 100%;
}

.NewUserOptions {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-left: 10%;
    margin-right: 10%;
}

.NewUserOptions .card
{
    width: 400px;
    margin-bottom: 50px;
    background-color: #fbfbff;
    background-clip: border-box;
    padding: 1rem;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2.5px;
    border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
}




.postsBackground {
    background: linear-gradient(-145deg, #f4a09c, #b83cc3, #7896fe ,#78f9ff, #40ff84);
    background-size: 400% 400%;
    animation: gradient 12s ease infinite ;
  
    border-bottom-left-radius: 90%;
    border-top-left-radius: 0% ;
    overflow:hidden;
    position: absolute;
    right: 0px;
    transform: translateY(-75px);
    height: 140px;
    width: 30%;
}

.posts-title{
    padding-bottom: 50px;
}